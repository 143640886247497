(function () {
  /**
   * Class N2Toast
   * Based on Bootstrap Toast Component
   */
  class N2Toast {
    /**
     * @constructor
     */
    constructor() {
      this.toasts = {};
      this.initDone = false;
    }

    /**
     * Get unique id
     * @param prefix
     * @returns {string}
     */
    static getUniqueId(prefix) {
      prefix = prefix || 'toast';

      function uId() {
        function s4() {
          return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }

        return `${prefix}_${new Date().getTime() + s4() + s4()}`;
      }

      return uId();
    }

    /**
     * Create and show new toast message
     * @param {Object} data
     * @returns {Object|Null}
     */
    showToast(data) {
      this.init();

      const toast = this.createToast(data);

      if (data.prevInstance) {
        const { instance } = data.prevInstance;

        if (instance._element) {
          instance.hide();
        }
      }

      if (toast) {
        toast.instance.show();
      }

      return toast;
    }

    /**
     * Send message to Sentry if it contains in data for toast
     * @param {Object} message
     */
    static sendToSentry(message) {
      if (message && message.captureException) {
        if (window.Sentry) {
          if (!message.extras) {
            window.Sentry.captureException(new Error(message.captureException));
          } else {
            window.Sentry.withScope((scope) => {
              scope.setExtras(message.extras);
              window.Sentry.captureException(new Error(message.captureException));
            });
          }
        } else {
          // eslint-disable-next-line no-console
          console.error(new Error(message.captureException));
        }
      }
    }

    /**
     * Create new toast message
     * @param {Object} data
     * @returns {Object|Null}
     */
    createToast(data) {
      this.init();

      // if we can throw only one toast by some criterion,
      // let`s try to find already thrown toast
      if (data.uniqueCriterion) {
        const result = Object.keys(this.toasts).some(
          toastId => this.toasts[toastId].uniqueCriterion === data.uniqueCriterion
        );

        if (result) {
          return null;
        }
      }

      const toast = document.createElement('div');
      const id = N2Toast.getUniqueId();
      toast.setAttribute('id', id);
      toast.classList.add('toast');

      if (data.editorToast) {
        toast.classList.add('n2-editor-toast');
      }

      const attrs = {
        role: 'status',
        'aria-live': 'polite',
        'aria-atomic': 'true',
      };

      if (data.type === N2Toast.types.error) {
        attrs.role = 'alert';
        attrs['aria-live'] = 'assertive';
        toast.classList.add('n2-toast-error');
      }

      Object.keys(attrs).forEach(attr => toast.setAttribute(attr, attrs[attr]));

      let toastHeader;
      if (data.title) {
        // Create toast header
        toastHeader = document.createElement('template');
        toastHeader.innerHTML = `<div class="toast-header">
          <strong class="me-auto">${data.title}</strong>
        </div>`;
        toastHeader = toastHeader.content.firstChild;
      }

      // Create close button
      let closeBtn = document.createElement('template');
      closeBtn.innerHTML = '<button type="button" class="btn-close btn-close-sm" data-bs-dismiss="toast" aria-label="Close"></button>';
      closeBtn = closeBtn.content.firstChild;

      // Create toast body
      let toastBody = document.createElement('template');
      toastBody.innerHTML = `<div class="toast-body">${data.content}</div>`;
      toastBody = toastBody.content.firstChild;

      if (toastHeader) {
        toast.appendChild(toastHeader);
      }

      toast.appendChild(toastBody);
      toast.appendChild(closeBtn);

      this.stackContainer.appendChild(toast);

      const options = {
        autohide: true,
        delay: 4000,
        ...(data.options || {}),
      };

      const toastInstance = new window.bootstrap.Toast(toast, options);

      this.toasts[id] = {
        element: toast,
        instance: toastInstance,
        uniqueCriterion: data.uniqueCriterion || null,
      };

      const events = data.events || {};
      Object.keys(events).forEach(function (event) {
        var handler = events[event];
        toast.addEventListener(event, handler);
      });

      const link = toast.querySelector('.n2-beacon-ask');
      const triggerClick = () => {
        document.dispatchEvent(new CustomEvent('beacon.ask'));
      };

      if (link) {
        link.addEventListener('click', triggerClick);
      }

      /**
       * Listen toast close and cleanup
       */
      let onClosed = () => {
        toast.removeEventListener('hidden.bs.toast', onClosed);
        if (link) {
          link.removeEventListener('click', triggerClick);
        }
        Object.keys(events).forEach(function (event) {
          var handler = events[event];
          toast.removeEventListener(event, handler);
        });
        toastInstance.dispose();
        toast.parentElement.removeChild(toast);
        delete this.toasts[id];
      };

      onClosed = onClosed.bind(this);
      toast.addEventListener('hidden.bs.toast', onClosed);

      N2Toast.sendToSentry(data);

      return this.toasts[id];
    }

    /**
     * Init toast plugin
     */
    init() {
      if (!this.initDone) {
        this.stackContainer = document.getElementById('n2-toast-stack-container');
        this.initDone = true;
      }
    }
  }

  /**
   * Toast types, for styling toast component
   * @type {{success: number, error: number, info: number}}
   */
  N2Toast.types = {
    info: 0,
    success: 1,
    error: 2,
  };

  // export class
  window.N2Toast = N2Toast;
  // export instance
  window.n2Toast = new N2Toast();
}());
